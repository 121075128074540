import React, { useState } from "react";
import "../../css/home/github_snake.css";

const GithubSnake = () => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  if (imageError) {
    return null; // 图片加载失败时返回 null，即不渲染任何内容
  }

  return (
    <div>
      <section className="colorlib-about" data-section="github_snake">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-12">
              <div
                className="row row-bottom-padded-sm animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="col-md-12">
                  <div
                    className="about-desc animate-box"
                    style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                  >
                    <span className="heading-meta">Code Commitments</span>
                    <h2 className="colorlib-heading">
                      Tracking Daily Code Progress
                    </h2>
                    <img
                      src="https://raw.githubusercontent.com/crabsatellite/crabsatellite/main/assets/github-snake.svg"
                      alt="Github Snake"
                      style={{
                        display: "block",
                        maxWidth: "100%",
                        height: "auto",
                        justifyContent: "center",
                      }}
                      onError={handleImageError} // 图片加载失败时触发
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 animate-box">
              <p>
                <a
                  href="https://github.com/crabsatellite"
                  className="btn btn-primary btn-lg btn-github-snake"
                >
                  GO TO MY GITHUB
                  <i className="icon-github" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GithubSnake;
